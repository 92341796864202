<template>
    <div class="active modal-register">
        <div class="container-modal form-modal">
            <div class="container-holder">
                <div class="header-modal">
                    <div class="icon"><icon-login /></div>
                    <div class="title">{{ $t('login.title') }}</div>
                </div>
                
                <form @submit.prevent="login" method="post">
    			    <div class="form-section-register">
                        <div class="input-login-group-tbf w-tbf-100" v-bind:class="{has_error: $v.email.$error, complete: email != '' && !$v.email.$error}">
                            <div class="input-box">
                                <div class="icon-right-new"><email-icon /></div>
						        <div class="required-icon">*</div>
                                <input type="email" :placeholder="$t('general.email-placeholder')" class="input-text" v-model="email">
                            </div>
                            <div class="error-msg" v-if="$v.email.$error || error">{{ $t('login.login-error') }}</div>
                        </div>
                        <div class="input-login-group-tbf w-tbf-100" v-bind:class="{has_error: $v.password.$error, complete: password != '' && !$v.password.$error}">
                            <div class="input-box">
                                <div class="icon-right-new"><password-icon /></div>
						        <div class="required-icon">*</div>
                                <input type="password" :placeholder="$t('login.password-placeholder')" class="input-text" v-model="password">
                            </div>
                        </div>
                    </div>
                    <div class="submit-footer">
                        <div class="submit-form">
                            <button id="buttonLogin" class="button-submit" type="submit">
								<div class="loader-spin" v-if="loadingSubmit"></div>
                                <div class="text" v-else>{{$t('login.login-button')}}</div>
                            </button>
                        </div>

                        <div class="bottom-links">
                            <router-link :to="{path: '/price'}" class="go-register">{{ $t('login.no-account') }}</router-link>
                            <router-link :to="{path: '/password/forgot' }" class="forgot-password">{{ $t('login.forgot-password') }}</router-link>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import '../../libraries/jquery.transit.min.js'
    window.buzz = require('../../libraries/buzz.min.js')
    import '../../libraries/flappy.js'

    import IconLogin from '../Icons/Login';
    import EmailIcon from '../Icons/Email';
    import PasswordIcon from '../Icons/Password';

    import { required, email } from 'vuelidate/lib/validators'

    export default {
        data(){
            return {
                error: false,
                password: '',
                email: '',
                options: [
                    { title: 'Romana', img: '/build/images/ro.png' },
                    { title: 'Español', img: '/build/images/es.png' },
                ],
                value_language: '',
                activeTooltip: '',
                loadingSubmit: false
            }
        },
        components: {
            EmailIcon,
            PasswordIcon,
            IconLogin
        },
        validations: {
            email: {required, email},
            password: {required}
        },
        methods: {
            login(){
                this.error = false
                var buttonName = 'buttonLogin'
                var btnSubmit = document.getElementById(buttonName);
                btnSubmit.disabled = true
                this.loadingSubmit = true
                
                var app = this
                this.$auth.login({
                    data: {
                        email: app.email, password: app.password
                    },
                    fetchUser: true,
                    staySignedIn: true
                })
                .then(() => {
                    window.location.replace(`${process.env.VUE_APP_ORGANIGRAM_FE_LINK}/dashboard`);
                    return true;
                }, (res) => {
                    let response = res.response.data
                    if(response.status == 'error'){
                        this.error = true

                        btnSubmit.disabled = false
                        this.loadingSubmit = false
                    }
                })
                .finally(() => {
                    setTimeout(()=>{
                        btnSubmit.disabled = false
                        this.loadingSubmit = false
                    }, 1500)
                })
            },
        }
    }
</script>